import initialState, { defaultState } from "../initialState";
import constants from "../actions/constants";

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const loadingReducer = (state = initialState.loading, action) => {
    // console.log("Running action:", action);
    const newState = {...state};
    
    switch(action.type)
    {
        case constants.LOADING_ACTIVATE:
            newState.message = action.value;
            newState.is_loading = true;
            return newState;
        case constants.LOADING_RESET:
            newState.message = "Loading Info";
            newState.is_loading = false;
            return newState;
        default:
            return newState;
    }
}

export default loadingReducer;