import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Display from "../containers/Display";
import AccountManagement from "../containers/AccountManagement";
import Accounts from "../containers/Accounts";
import Users from "../containers/Users";
import User from "../containers/User";
import Invoice from "../containers/Invoice";

const ClientRoutes = () =>
  <Switch>
    <Route path="/users" exact component={Users} />;
    <Route path='/users/:userid' exact component={User} />;
    <Route path='/users/:userid/accounts' exact component={AccountManagement} />;
    <Route path="/accounts" exact component={Accounts} />;
    <Route path='/accounts/:custmastid/invoices' exact component={Display} />;
    <Route path='/accounts/:custmastid/invoices/:invno' exact component={Invoice} />;
    <Route render={props => {
      // console.log("rerouting to accounts");
      return <Redirect to={{ pathname: '/accounts', state: { from: props.location } }} />;
    }} />
  </Switch>
export default ClientRoutes;

