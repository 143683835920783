// Calling it index allows the import to be just import Store from 'store'.
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './reducers';
import constants from './actions/constants';

const store = createStore(
    reducer,
    // preloadedState,
    composeWithDevTools(
        applyMiddleware(thunk),
    )
);
// Called when the state has changed.
store.subscribe(() => {
    const state = store.getState();
    // console.log('currenstate', state);
    // const storeState = {...state};

    // only store token
    const storeState = {
        auth: {
            token: state.auth.token,
        },
    };

    /*
     * Dont do this, resets all your states all the time
     */

    // storeState.auth.loading = false;
    // storeState.auth.error = '';

    // storeState.load.loading = false;
    // storeState.load.error = '';

    // storeState.save.loading = false;
    // storeState.save.error = '';

    // console.log('storeState', storeState);
    localStorage.setItem(constants.LOCAL_STORAGE_KEY, JSON.stringify(storeState));
}) 

export default store;