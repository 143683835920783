import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import store from './store';
import { Provider } from 'react-redux';

// console.log(initialConfig);
// config.set(initialConfig);

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <Switch>
                <Route path="/accounts/:custmastid" component={App} />
                <Route path="*" component={App} />
            </Switch>
        </HashRouter>
    </Provider>,
    document.getElementById("root")
);
registerServiceWorker();