import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel, } from "react-bootstrap";
import "./Request.css";
import { connect } from 'react-redux';
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import DatePicker from 'react-16-bootstrap-date-picker';
import  * as moment from 'moment';

const formState = {
    location: "",
    date: moment().toISOString(),
    request_type: "",
    transfer_destination: "",
    employee_name: "",
    employee_position: "",
    shirt_size: "",
    jacket_size: "",
    pants_waist: "",        
    pants_length: "",
    receiver: "",
};

class Request extends Component {

    constructor(props) {
        super(props);
    

        this.state = Object.assign({},formState,{
            loading: false,
            email_list: [],
        });
    }

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    validateValue = (value) => {
        return value.length > 1 ? 'success' : null;
    }

    componentDidMount() {

        this.props.activateLoad('Loading Form');

        if (this.props.user.radb.contact_email === null) {
            let endPoint = this.props.apiUrl + '/v1/emails';
            let emailLoading = fetch(endPoint, {
                method: 'GET',
                //credentials: 'include',        
                headers: {
                Authorization: 'Bearer '+this.props.token,
                }
            })
            .then(async response => {
                if (response.status>=200 && response.status<300) {
                return response.json();
                }
                const obj = await response.json();
                if ('errors' in obj) {
                throw new Error(obj.errors.join("\n"));
                } else {
                throw new Error('Unknown error');
                }
            })
            .then(json => {
                this.setState({email_list: json});
                return true;
            })
            .catch(e => {
                //console.log('custmast');
                alert(e.message);
                return false;
            });

            Promise.all([emailLoading])
            .then(results => {
                // console.log(emailLoading);
                // console.log(this.state.email_list);
            })
            .catch(e => {   
                alert('User and/or Account data could not be resolved.');
                this.props.history.push(encodeURI('/accounts'));
            });
        }

        this.props.resetLoad();

    }

    handleSubmit = async event => {
        event.preventDefault();

        const { custmastid } = this.props.match.params;

        this.props.activateLoad('Sending Request');
        const formData = {
            location: this.state.location,
            date: this.state.date,
            request_type: this.state.request_type,
            transfer_destination: this.state.request_type === 'Transfer' ? this.state.transfer_destination : null,
            employee_name: this.state.employee_name,
            employee_position: this.state.employee_position,
            shirt_size: this.state.shirt_size,
            jacket_size: this.state.jacket_size,
            pants_waist: this.state.pants_waist,
            pants_length: this.state.pants_length,
            receiver: this.props.user.radb.contact_email === null ? this.state.receiver : this.props.user.radb.contact_email,
            custmast: custmastid,
        };
        
        const sent = await fetch(this.props.apiUrl + '/v1/request', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.props.token, 
            },
            body: JSON.stringify(formData),
        })
        .then(async response => {
            if (response.status>=200 && response.status<300) {
                return response.json();
            }
            const obj = await response.json();
            if ('errors' in obj) {
                throw new Error(obj.errors.join("\n"));
            } else {
                throw new Error('Unknown error');
            }
        })
        .then(obj => {
            return true;
        })
        .catch(e => {
            this.setState({loading: false});
            alert(e.message + '!');
            this.props.resetLoad();
            return false;
        });

        // console.log(sent);

        if (sent) {
            this.props.resetLoad();
        }
    }

    validateForm() {
        return (this.state.location.length > 1
            && this.state.date.length > 1
            && this.state.request_type.length > 1
            && (this.state.request_type !== 'Transfer' || this.state.transfer_destination.length > 1)
            && this.state.employee_name.length > 1
            && this.state.employee_position.length > 1
            && this.state.shirt_size.length > 1
            && this.state.jacket_size.length > 1
            && this.state.pants_waist.length > 1
            && this.state.pants_length.length > 1
            && (this.props.user.radb.contact_email !== null || this.state.receiver.length > 1));
    }

    render() {

        return (<div id="requestpanel" className="Request panel panel-default padless marginless">
            <div className="panel-heading panelheader">
                <div className="titled bold-text align-center">
                    Service Request Form
                </div>
            </div>
            <div className="panel-body">
                <form className="vert-spaced" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 right-leaning">
                            <FormGroup controlId="location" bsSize="large" validationState={this.validateValue(this.state.location)}>
                                <ControlLabel>Location<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} type="text" value={this.state.location} placeholder="Location"/>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                        <div className="col-sm-6 left-leaning">
                            <FormGroup controlId="date" bsSize="large" validationState={this.state.date < moment().startOf('day').toISOString() ? 'error' : 'success'}>
                                <ControlLabel>Date<b className='asterisk'> *</b></ControlLabel>
                                {/* <FormControl onChange={this.handleChange}  type="text" value={this.state.date}  placeholder="Date"/> */}
                                <DatePicker value={this.state.date} showClearButton={false} onChange={date => this.setState({ date })} placeholder="Date" />
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 right-leaning">
                            <FormGroup controlId="request_type" bsSize="large" validationState={this.validateValue(this.state.request_type)}>
                                <ControlLabel>Request Type<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} value={this.state.request_type} componentClass="select" placeholder="select">
                                    <option value="0" >Select Type</option>
                                    <option value="New Hire">New Hire</option>
                                    <option value="Uniform Change">Uniform Change</option>
                                    <option value="Cancellation">Cancellation</option>
                                    <option value="Transfer">Transfer</option>
                                </FormControl>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                        <div className="col-sm-6 left-leaning">
                            {this.state.request_type === 'Transfer' &&
                            <FormGroup controlId="transfer_destination" bsSize="large" validationState={this.validateValue(this.state.transfer_destination)}>
                                <ControlLabel>Transfer Destination<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} type="text" value={this.state.transfer_destination} placeholder="Destination"/>
                                <FormControl.Feedback />
                            </FormGroup>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 side-padded">
                            <FormGroup controlId="employee_name" bsSize="large" validationState={this.validateValue(this.state.employee_name)}>
                                <ControlLabel>Employee Name<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} type="text" value={this.state.employee_name} placeholder="Full Name"/>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 side-padded">
                            <FormGroup controlId="employee_position" bsSize="large" validationState={this.validateValue(this.state.employee_position)}>
                            <ControlLabel>Employee Position<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} type="text" value={this.state.employee_position} placeholder="Position"/>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 right-leaning">
                            <FormGroup controlId="shirt_size" bsSize="large" validationState={this.validateValue(this.state.shirt_size)}>
                                <ControlLabel>Shirt Size<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} value={this.state.shirt_size} componentClass="select" placeholder="select">
                                    <option value="0">Select Size</option>
                                    <option value="X-Small">X-Small</option>
                                    <option value="Small">Small</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Large">Large</option>
                                    <option value="X-Large">X-Large</option>
                                    <option value="XX-Large">XX-Large</option>
                                    <option value="XXX-Large">XXX-Large</option>
                                    <option value="None">None</option>
                                </FormControl>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                        <div className="col-sm-6 left-leaning">
                            <FormGroup controlId="jacket_size" bsSize="large" validationState={this.validateValue(this.state.jacket_size)}>
                                <ControlLabel>Jacket Size<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} value={this.state.jacket_size} componentClass="select" placeholder="select">
                                    <option value="0">Select Size</option>
                                    <option value="X-Small">X-Small</option>
                                    <option value="Small">Small</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Large">Large</option>
                                    <option value="X-Large">X-Large</option>
                                    <option value="XX-Large">XX-Large</option>
                                    <option value="XXX-Large">XXX-Large</option>
                                    <option value="None">None</option>
                                </FormControl>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 right-leaning">
                            <FormGroup controlId="pants_waist" bsSize="large" validationState={this.validateValue(this.state.pants_waist)}>
                                <ControlLabel>Pants Waist<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} type="text" value={this.state.pants_waist} placeholder="Waist"/>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                        <div className="col-sm-6 left-leaning">
                            <FormGroup controlId="pants_length" bsSize="large" validationState={this.validateValue(this.state.pants_length)}>
                                <ControlLabel>Pants Length<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} type="text" value={this.state.pants_length} placeholder="Length"/>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                    </div>
                    {this.props.user.radb.contact_email === null && <div className="row">
                        <div className="col-sm-12 side-padded">
                            <FormGroup controlId="receiver" bsSize="large" validationState={this.validateValue(this.state.receiver)}>
                                <ControlLabel>Send To<b className='asterisk'> *</b></ControlLabel>
                                <FormControl onChange={this.handleChange} value={this.state.receiver} componentClass="select" placeholder="select">
                                    <option value="0">Select Email</option>
                                    {this.state.email_list.map(element => {
                                        // console.log(element);
                                        return <option value={element}>{element}</option>
                                    })}
                                </FormControl>
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-sm-12 tightened">
                            <Button block bsSize="large" disabled={!this.validateForm()} type="submit">Submit</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>);
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
    const { auth: { user, apiUrl, token }} = state;
    return {
        //props.token will now be connected to the store's state.token.'
        user,
        apiUrl,
        token,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          activateLoad: (message) => dispatch(activateLoading(message)),
          resetLoad: () => dispatch(resetLoading()),
          // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
      };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Request);