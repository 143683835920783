import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../containers/Login";
import Register from "../containers/Register";

const DefaultRoutes = () =>
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/register" exact component={Register} />
    <Route render={props => {
      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }} />
  </Switch>
export default DefaultRoutes;