import initialState, { defaultState } from "../initialState";
import constants from "../actions/constants";
import {default as uuid} from "uuid";

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const authReducer = (state = initialState.auth, action) => {
    // console.log("Running action:", action);
    const newState = {...state};
    
    switch(action.type)
    {
        case constants.SET_TOKEN:
            // console.log('SET_TOKEN');
            newState.token = action.value;
            return newState;
        case constants.SET_USER:
            // console.log('SET_USER');
            newState.user = action.value;
            return newState;
        case constants.LOGIN_TRY:
            // console.log('LOGIN_TRY');
            newState.loading = true;
            newState.message = {
                id: null,
                value: '',
            };
            return newState;
        case constants.LOGIN_SUCCESS:
            // console.log('LOGIN_SUCCESS');
            newState.tokenChecked = true;
            newState.authorized = true;
            newState.loading = false;
            newState.message = {
                id: null,
                value: '',
            };
            newState.registration = null;
            return newState; 
        case constants.LOGIN_ERROR:
            // console.log('LOGIN_ERROR');
            newState.tokenChecked = true;
            newState.token = '';
            newState.authorized = false;
            newState.loading = false;
            newState.message = {
                id: uuid.v4(),
                value: action.value,
            };
            newState.user = null;
            newState.registration = null;
            return newState;
        case constants.LOGOUT:
            // console.log('LOGOUT');
            const logoutAuthState = {...defaultState.auth};
            logoutAuthState.tokenChecked = true;
            logoutAuthState.token = '';
            return logoutAuthState;
        case constants.REGISTER_TRY:
            // console.log('REGISTER_TRY');
            newState.registration = action.value;
            return newState;
        default:
            // console.log('DEFAULT');
            return newState;
    }
}

export default authReducer;