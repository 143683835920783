import React, { Component } from "react";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import { connect } from 'react-redux';
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./SimpleCount.css";
import * as moment from 'moment';
import { Glyphicon } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';

class SimpleCount extends Component {
    constructor(props) {
        super(props);

        this.state = {
          date: moment().toISOString(),
        }

        this.CustMast = {};
        this.SimpleCount = [];
    }

    componentDidMount() {
        this.props.activateLoad('Loading Data');
        this.getData();
        this.props.resetLoad();
    }

    async getData() {
        const { custmastid } = this.props.match.params;
        const { user, apiUrl, token } = this.props;

        fetch(apiUrl + '/v1/' + user.radb.connection + '/custmast/' + custmastid, {
            method: 'GET',
            //credentials: 'include',        
            headers: {
                Authorization: 'Bearer '+token,
            }
        })
        .then(async response => {
            if (response.status>=200 && response.status<300) {
                return response.json();
            }
            const obj = await response.json();
            if ('errors' in obj) {
                throw new Error(obj.errors.join("\n"));
            } else {
                throw new Error('Unknown error');
            }
        })
        .then(json => {
            this.CustMast = json;
            this.CustMast.ARCOMPANY = this.CustMast.ARCOMPANY.replace(/\0.*$/g,'');
            this.CustMast.ADDR1 = this.CustMast.ADDR1.replace(/\0.*$/g,'');
            this.CustMast.ADDR2 = this.CustMast.ADDR2.replace(/\0.*$/g,'');
            this.CustMast.ARCITY = this.CustMast.ARCITY.replace(/\0.*$/g,'');
            this.CustMast.ARSTATE = this.CustMast.ARSTATE.replace(/\0.*$/g,'');
            this.CustMast.ARZIP = this.CustMast.ARZIP.replace(/\0.*$/g,'');
            // console.log(this.CustMast);
        })
        .catch(e => {
            //console.log('custmast');
            alert(e.message);
            return false;
        });
    }

    render() {

      return (
        <div className="SimpleCount panel panel-default padless">
            <div id="mainheader" className="panel-heading">
                <div id="coname" className="title align-center">
                    {this.CustMast.ARCOMPANY}
                </div>
                <div className="bold-text align-center">
                    SimpleCount
                </div>
            </div>
            <div style={{"paddingBottom": "0px"}} className="panel-body spacer2">>
                <div className="panel panel-default padless row">
                    <div className="panel-heading">
                        <div className="row" id="detailshead">
                            <div className="col-sm-11 floatleft">
                                <h4>SimpleCount</h4>
                            </div>
                            <div className="col-sm-1 floatright">
                                <Button className="printbutton" bsSize="large" onClick={() => this.getPdf()}>
                                    <Glyphicon glyph="print"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6 align-center">
                            <FormGroup controlId="date" bsSize="large" validationState={this.state.date < moment().startOf('day').toISOString() ? 'error' : 'success'}>
                                <ControlLabel>Date</ControlLabel>
                                <DatePicker value={this.state.date} showClearButton={false} onChange={date => this.setState({ date })} placeholder="Date" />
                                <FormControl.Feedback />
                            </FormGroup>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { user, apiUrl, token }} = state;
  return {
      //props.token will now be connected to the store's state.token.'
      user,
      apiUrl,
      token,
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
        activateLoad: (message) => dispatch(activateLoading(message)),
        resetLoad: () => dispatch(resetLoading()),
        // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleCount);