import constants from './constants';

export const activateLoading = message => ({
  type: constants.LOADING_ACTIVATE, 
  value: message
});

export const resetLoading = () => {
  return { type: constants.LOADING_RESET };
}
