import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Invoice.css";
import * as moment from 'moment';
import { connect } from 'react-redux';
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import { Glyphicon } from 'react-bootstrap';

class Invoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            generatingPdf: false,
        };

        this.CustMast = {};
        this.Invoice = {};
        this.Payments = [];
        this.Urdthists = [];
    }

    // onRowSelect = (row, isSelected, e) => {
    //     this.props.history.push(encodeURI('/display/' + row['InvNo'].trim()));
    // }

    getPdf() {
        this.setState({generatingPdf: true});
        
        const { invno } = this.props.match.params;

        this.props.activateLoad('Downloading Invoice');
        fetch(this.props.apiUrl + '/v1/' + this.props.user.radb.connection + '/invoice/' + invno, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer '+this.props.token,
            }
        })
        .then(async response => {
            if (response.status>=200 && response.status<300) {
              return response.blob();
            }
            const obj = await response.json();
            if ('errors' in obj) {
              this.setState({generatingPdf: false});
              throw new Error(obj.errors.join("\n"));
            } else {
              this.setState({generatingPdf: false});
              throw new Error('Unknown error');
            }
          })
        .then(blob => {
            var newBlob = new Blob([blob], {type: "application/pdf"});
            var filename = "invoice-" + this.props.selectedInvno + ".pdf";

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var result = window.navigator.msSaveOrOpenBlob(newBlob, filename);
                // console.log(result);
                this.setState({generatingPdf: false});
                return;
            }
            
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download=filename;
            document.body.appendChild(link);
            link.click();
            this.setState({generatingPdf: false});
            setTimeout(function(){
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
            }, 100);
            this.props.resetLoad();
        });
    }

    componentDidMount() {

        this.props.activateLoad('Loading Invoice');
        const { custmastid, invno } = this.props.match.params;

        let custmastEndPoint = this.props.apiUrl + '/v1/' + this.props.user.radb.connection + '/custmast/' + custmastid;
        let custmastload = fetch(custmastEndPoint, {
            method: 'GET',
            //credentials: 'include',        
            headers: {
              Authorization: 'Bearer '+this.props.token,
            }
        })
        .then(async response => {
            if (response.status>=200 && response.status<300) {
              return response.json();
            }
            const obj = await response.json();
            if ('errors' in obj) {
              throw new Error(obj.errors.join("\n"));
            } else {
              throw new Error('Unknown error');
            }
          })
        .then(json => {
            //console.log(json);
            this.CustMast = json;
            this.CustMast.ARCOMPANY = this.CustMast.ARCOMPANY.replace(/\0.*$/g,'');
            this.CustMast.ADDR1 = this.CustMast.ADDR1.replace(/\0.*$/g,'');
            this.CustMast.ADDR2 = this.CustMast.ADDR2.replace(/\0.*$/g,'');
            this.CustMast.ARCITY = this.CustMast.ARCITY.replace(/\0.*$/g,'');
            this.CustMast.ARSTATE = this.CustMast.ARSTATE.replace(/\0.*$/g,'');
            this.CustMast.ARZIP = this.CustMast.ARZIP.replace(/\0.*$/g,'');
        })
        .catch(e => {
            //console.log('custmast');
            alert(e.message);
            return false;
        });

        let openitemEndPoint = this.props.apiUrl + '/v1/' + this.props.user.radb.connection + '/openitem?perPage=0&invno='+invno;

        let openitemload = fetch(openitemEndPoint, {
            method: 'GET',
            //credentials: 'include',        
            headers: {
              Authorization: 'Bearer '+this.props.token,
            }
        })
        .then(async response => {
            if (response.status>=200 && response.status<300) {
              return response.json();
            }
            const obj = await response.json();
            if ('errors' in obj) {
              throw new Error(obj.errors.join("\n"));
            } else {
              throw new Error('Unknown error');
            }
          })
        .then(json => {
            //console.log(json);
            let data = [];
            // iterate over json and populate open items
            if (json.data.length>0) {
                
                data = json.data.map((v,i) => {
                    
                    // txn data
                    v.txnid = v.OpenitemID;
                    v.txnpostdate = moment(v.POSTDATE, "YYYYMMDD").format("MM/DD/YYYY");

                    // invoice data
                    v.totaltax = parseFloat(v.TAXAMT1) + parseFloat(v.TAXAMT2) + parseFloat(v.TAXAMT3) + parseFloat(v.TAXAMT4);
                    if (isNaN(v.totaltax)) v.totaltax = 0;
                    v.totaltax = v.totaltax >= 0 ? "$" + parseFloat(v.totaltax).toFixed(2) : "-$" + (-1 * parseFloat(v.totaltax)).toFixed(2);
                    v.txndate = moment(v.IDATE, "YYYYMMDD").format("MM/DD/YYYY");
                    v.txnduedate = v.ITYPE==='1' ? moment(v.DUEDATE, "YYYYMMDD").format("MM/DD/YYYY") : "N/A";
                    v.txnamount = v.OIAMT >= 0 ? "$" + parseFloat(v.OIAMT).toFixed(2) : "-$" + (-1 * parseFloat(v.OIAMT)).toFixed(2);
                    v.remainingbalance = v.INVBAL >= 0 ? "$" + parseFloat(v.INVBAL).toFixed(2) : "-$" + (-1 * parseFloat(v.INVBAL)).toFixed(2);
                    v.txndesc = v.DESCRIP.replace(/\0.*$/g,'');
                    v.txnterms = v.TERMS.replace(/\0.*$/g,'');

                    //console.log(v.ARNO);
                    return v;
                });

                data.sort((a,b) => {
                    // console.log(a,b);
                    if (a.txnpostdate===b.txnpostdate) {
                        return a.txnid>b.txnid ? -1 : 1;
                    } else {
                        return a.txnpostdate>b.txnpostdate ? -1 : 1
                    }
                });

                // let itemIDs = [];
                // this.OpenItems = this.CustInquiry.OpenItems.filter(item => {
                //     if (typeof itemIDs.find(itemID => item.OpenitemID === itemID) === 'undefined') {
                //         itemIDs.push(item.OpenitemID);
                //         return true;
                //     } else {
                //         return false;
                //     }
                // });
            }
            return data;
        })
        .then(data => {
            this.Payments = data.filter(v => v.ITYPE !== '1');
            this.Invoice = data.filter(v => v.ITYPE === '1').shift();
        })
        .catch(e => {
            //console.log('openitem');
            alert(e.message);
            return false;
        });

        let urdthistEndPoint = this.props.apiUrl + '/v1/' + this.props.user.radb.connection + '/urdthist?perPage=0&invno='+invno;

        let urdthistload = fetch(urdthistEndPoint, {
            method: 'GET',
            //credentials: 'include',        
            headers: {
                Authorization: 'Bearer '+this.props.token,
            }
        })
        .then(async response => {
            if (response.status>=200 && response.status<300) {
              return response.json();
            }
            const obj = await response.json();
            if ('errors' in obj) {
              throw new Error(obj.errors.join("\n"));
            } else {
              throw new Error('Unknown error');
            }
          })
        .then(async json => {
            //console.log(json);
            if (json.data.length>0) {
                this.Urdthists = json.data.map((v,i) => {
                    v.ADESC = v.ADESC.replace(/\0.*$/g,'');
                    v.FREQ = v.FREQ.replace(/\0.*$/g,'');
                    v.INVNO = v.INVNO.replace(/\0.*$/g,'');
                    v.CHRGAMT = v.CHRGAMT < 0 ? "-$" + (-1 * parseFloat(v.CHRGAMT)).toFixed(2) : "$" + parseFloat(v.CHRGAMT).toFixed(2);
                    v.TAXTOTALAMT = parseFloat(v.TAX1AMT) + parseFloat(v.TAX2AMT) + parseFloat(v.TAX3AMT) + parseFloat(v.TAX4AMT);
                    v.TAXTOTALAMT = v.TAXTOTALAMT < 0 ? "-$" + (-1 * parseFloat(v.TAXTOTALAMT)).toFixed(2) : "$" + parseFloat(v.TAXTOTALAMT).toFixed(2);
                    v.MANNOSUFFIX = v.MANNO + v.SUFFIX;
                    return v;
                }); 
            }
            return true;
        })
        .catch(e => {
            //console.log('urdthist');
            alert(e.message);
            return false;
        });

        Promise.all([openitemload, urdthistload, custmastload])
        .then(results => {
            // console.log("promise then", results);
            if (results.find(v => v===false)) {
                let pushString = '/accounts/' + custmastid + '/invoices/' + invno;
                this.props.history.push(pushString);
            }
            this.props.resetLoad();
        })
        .catch(e => {
            // console.log("promise catch", error);
            let catchString = '/accounts/' + custmastid + '/invoices/';
            this.props.history.push(catchString);
        });
    }

    render() {

        const { custmastid, invno } = this.props.match.params;
        if (this.CustMast.CustmastID && custmastid !== this.CustMast.CustmastID + "") {
            this.props.history.push(encodeURI('/accounts/' + custmastid + '/invoices'));
        }

        const options = {
            clearSearch: true,
            searchField: this.createCustomSearchField,
            searchPosition: 'left',
            paginationPosition: 'bottom',
            scrollTop: 'top',
            // defaultSortName: 'InvoiceDate',
            // defaultSortOrder: 'desc'
        };

        return (<div className="Invoice panel panel-default padless">
            <div id="mainheader" className="panel-heading">
                <div id="coname" className="title align-center">
                    {this.CustMast.ARCOMPANY}
                </div>
                <div className="bold-text align-center">
                    Invoice #{invno}
                </div>
            </div>
            <div style={{"paddingBottom": "0px"}} className="panel-body spacer2">
                <div className="row">
                    <div className="panel panel-default col-sm-5 padless infopanel">
                        <div className="panel-heading">
                            <h4>Invoice</h4>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-sm-2">
                                    <ControlLabel>Tax:</ControlLabel>
                                </div>
                                <div className="col-sm-4">
                                    <FormControl type="text" disabled value={typeof this.Invoice === "undefined" ? "" : this.Invoice.totaltax} />
                                </div>
                                <div className="col-sm-2">
                                    <ControlLabel>Total:</ControlLabel>
                                </div>
                                <div className="col-sm-4">
                                    <FormControl type="text" disabled value={typeof this.Invoice === "undefined" ? "" : this.Invoice.txnamount} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <ControlLabel>Due:</ControlLabel>
                                </div>
                                <div className="col-sm-4">
                                    <FormControl type="text" disabled value={typeof this.Invoice === "undefined" ? "" : this.Invoice.txnduedate} />
                                </div>
                                <div className="col-sm-2">
                                    <ControlLabel>Bal:</ControlLabel>
                                </div>
                                <div className="col-sm-4">
                                    <FormControl type="text" disabled value={typeof this.Invoice === "undefined" ? "" : this.Invoice.remainingbalance} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default col-sm-7 padless paymentspanel">
                        <div className="panel-heading">
                            <h4>Payments</h4>
                        </div>
                        <div className="panel-body">
                            <BootstrapTable search data={this.Payments} maxHeight='120' options={options} striped={true} hover={false}>
                                <TableHeaderColumn dataField="txndate" dataAlign="center" isKey={true}>Invoice Date</TableHeaderColumn>
                                <TableHeaderColumn dataField="txnduedate" dataAlign="center">Due Date</TableHeaderColumn>
                                <TableHeaderColumn dataField="txnamount" dataAlign="center">Amount</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
                <div className="panel panel-default padless row">
                    <div className="panel-heading">
                        <div className="row" id="detailshead">
                            <div className="col-sm-11 floatleft">
                                <h4>Invoice Details</h4>
                            </div>
                            <div className="col-sm-1 floatright">
                                <Button className="printbutton" bsSize="large" onClick={() => this.getPdf()}>
                                    <Glyphicon glyph="print"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <BootstrapTable search data={this.Urdthists} maxHeight='410' options={options} striped={true} hover={false} pagination={true}>   
                            <TableHeaderColumn dataField="MANNOSUFFIX" width="70" dataAlign="center">Man No</TableHeaderColumn>                                 
                            <TableHeaderColumn dataField="MANNAME" width="200" dataAlign="center" isKey={true}>Item/Man</TableHeaderColumn>
                            <TableHeaderColumn dataField="ADESC" width="200" dataAlign="center">Description</TableHeaderColumn>
                            <TableHeaderColumn dataField="CHRGAMT" width="100" dataAlign="center">Charge</TableHeaderColumn>
                            <TableHeaderColumn dataField="TAXTOTALAMT" width="100" dataAlign="center">Tax</TableHeaderColumn>
                            <TableHeaderColumn dataField="TOTQTY" width="70" dataAlign="center">Total Qty</TableHeaderColumn>
                            <TableHeaderColumn dataField="CHGQTY" width="70" dataAlign="center">Chg Qty</TableHeaderColumn>
                            <TableHeaderColumn dataField="OPTQTY" width="70" dataAlign="center">Opt Qty</TableHeaderColumn>
                            <TableHeaderColumn dataField="OPTCHG" width="70" dataAlign="center">Opt Chg</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>
        </div>);
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
    const { auth: { user, apiUrl, token }} = state;
    return {
        //props.token will now be connected to the store's state.token.'
        user,
        apiUrl,
        token,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
      return {
          activateLoad: (message) => dispatch(activateLoading(message)),
          resetLoad: () => dispatch(resetLoading()),
          // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
      };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);