import { combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form';

import authReducer from "./authReducers";
import loadingReducer from "./loadingReducers";
import displayReducer from "./displayReducers";
// import loadReducer from "./load";
// import saveReducer from "./save";
// import listReducer from "./list";

// import initialState from "../initialState";


const reducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    display: displayReducer,
    // load: loadReducer,
    // form: formReducer,
    // save: saveReducer,
    // list: listReducer,
});

export default reducer;