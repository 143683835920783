// Redux action types.
const constants = {
    LOCAL_STORAGE_KEY:  'WEB_PORTAL',
    SET_TOKEN:          'SET_TOKEN',
    SET_USER:           'SET_USER',
    USER_ERROR:         'USER_ERROR',
    
    LOGIN_TRY:          'LOGIN_TRY',
    LOGIN_ERROR:        'LOGIN_ERROR',
    LOGIN_SUCCESS:      'LOGIN_SUCCESS',
    LOGOUT:             'LOGOUT',
    LOGIN_CLEAR_MSG:    'LOGIN_CLEAR_MSG',

    LOADING_ACTIVATE:   'LOADING_ACTIVATE',
    LOADING_RESET:      'LOADING_RESET',

    REGISTER_TRY:       'REGISTER_TRY',
}

export default constants;
