import React, { Component } from "react";
//import { BootstrapTable, TableHeaderColumn, SearchField } from "react-bootstrap-table";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { logout, checkToken } from '../store/actions/authActions';
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import { connect } from 'react-redux';
import { Button, ToggleButton, ToggleButtonGroup, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./User.css";
//import * as moment from 'moment';

class User extends Component {
    constructor(props) {
        super(props);

        this.UserInfo = {};
        this.newRolesArray = [];

        this.attachRef = target => this.setState({ target });

        this.state = {
          company: "",
          name: "",
          email: "",
          username: "",
          password: "",
          password_confirmation: "",
          changePassword: false,
          isUserClient: this.props.user.roles,
          editMode: false,
          submitting: false,
          loading: true,
          updating: false,
          show: false,
          currentrole: '',
          approved: false,
        };

    }

    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

    validateForm() {
      return (this.state.username.length > 0
       && this.state.name.length > 0
       && this.state.email.length > 0
       && ((this.state.password.length > 0 && this.state.password_confirmation.length > 0) || !this.state.changePassword));
    }

    async getData() {

        const { userid } = this.props.match.params;

        fetch(this.props.apiUrl + '/v1/user/' + userid, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + this.props.token, 
            }
        })
        .then(async response => {
            if (response.status>=200 && response.status<300) {
                return response.json();
            }
            const obj = await response.json();
            if ('errors' in obj) {
                throw new Error(obj.errors.join("\n"));
            } else {
                throw new Error('Unknown error');
            }
        })
        .then(json => {
            //console.log(json);
            this.UserInfo = json;
            console.log(this.UserInfo);
            this.UserInfo.roles.forEach(element => {
              if (element.name == 'client') {
                this.setState({isUserClient: true});
                // console.log('is client');
              }
            });
            this.setState({
              company: this.UserInfo.company,
              name: this.UserInfo.name,
              email: this.UserInfo.email,
              username: this.UserInfo.username,
              password: '',
              password_confirmation: '',
              approved: this.UserInfo.approved,
            });
            if (typeof this.UserInfo.roles !== 'undefined') {
              this.setState({currentrole: this.UserInfo.roles[0].name});
              // console.log(this.state.currentrole);
            }
        })
        .catch(e => {
            alert(e.message);
            this.props.history.push('/404');
        });
    }

    handleSubmit = async event => {
      event.preventDefault();

      this.props.activateLoad('Saving User');

      this.setState({updating: true});

      let userData = !(this.state.company == this.UserInfo.company && this.state.name == this.UserInfo.name && this.state.username == this.UserInfo.username && this.state.email == this.UserInfo.email && this.state.approved == this.UserInfo.approved);
      let passwordData = this.state.changePassword;
      let rolesData = !(this.UserInfo.isClient === this.state.isUserClient);
      console.log(rolesData);

      const information = {
        userData: userData,
        passwordData: passwordData,
        rolesData: rolesData,
        accountsData: false,
      }

      if (information.userData) {
        information.approved = this.state.approved;
        information.company = this.state.company;
        information.name = this.state.name;
        information.username = this.state.username;
        information.email = this.state.email;
      }

      if (information.passwordData) {
        information.password = this.state.password;
        information.password_confirmation = this.state.password_confirmation;
      }

      if (information.rolesData) {
        information.roles = this.state.isUserClient ? [2] : [1];
      }

      // console.log(this.UserInfo);
      
      const updated = await fetch(this.props.apiUrl + '/v1/' + this.props.user.radb.connection + '/user/' + this.UserInfo.id, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + this.props.token,
        },
        body: JSON.stringify(information),
      })
      .then(async response => {
        //console.log("Entering first THEN");
        if (response.status>=200 && response.status<300) {
          return response.json();
        }
        const obj = await response.json();
        if ('errors' in obj) {
          throw new Error(obj.errors.join("\n"));
        } else {
          throw new Error('Unknown error');
        }
      })
      .then(async obj => {
          //* syncronously set the state
          //console.log("Entering second THEN");
          return true;
      })
      .catch(e => {
        alert("ERROR: User Update Unsuccessful:\n" + e.message);
        //console.log('Error')
        return false;
      });

      if (updated) {
        this.setState({
          company: "",
          name: "",
          email: "",
          username: "",
          password: "",
          password_confirmation: "",
          changePassword: false,
          isUserClient: false,
          editMode: false,
          submitting: false,
          loading: true,
          updating: false,
          approved: false,
        });
        const { userid } = this.props.match.params;
        if (userid !== this.props.user.id.toString()) {
          this.props.history.push(encodeURI('/users/'));
        } else {
          this.props.handleLogout();
        }
      } else {
        //* COMMENTED OUT BECAUSE ALERT WAS REDUNDANT WITH ALERT INSIDE CATCH OF FETCH REQUEST
        // alert('Update unsuccessful. Check that all edited info is correct.');
        this.setState({ 
          password: '',
          password_confirmation: '',
          updating: false,
        });
      }    
      this.props.resetLoad();
    }

    componentDidMount() {
      this.props.activateLoad('Loading User');
      this.getData();
      this.props.resetLoad();
    }

    render() {

      // console.log(this.UserInfo);

      return (<div className="User"><form onSubmit={this.handleSubmit}>
        <FormGroup controlId="approved" bsSize="large">
          <ControlLabel className="buttonlabel">Approved?</ControlLabel>
          <ToggleButtonGroup className="left-margin" type="radio" name="approved" bsSize="large" value={this.state.approved ? 1 : 2}>
            <ToggleButton value={1} onClick={() => this.setState({approved: 1})}>Yes</ToggleButton>
            <ToggleButton value={2} onClick={() => this.setState({approved: 0})}>No</ToggleButton>
          </ToggleButtonGroup>
        </FormGroup>
        <FormGroup controlId="company" bsSize="large">
          <ControlLabel>Edit Company</ControlLabel>
          <FormControl
            type="text"
            defaultValue={this.UserInfo.company}
            onChange={this.handleChange}
            tabIndex={1}
          />
        </FormGroup>
        <FormGroup controlId="name" bsSize="large">
          <ControlLabel>Edit Full Name</ControlLabel>
          <FormControl
            type="text"
            defaultValue={this.UserInfo.name}
            onChange={this.handleChange}
            tabIndex={2}
          />
        </FormGroup>
        <FormGroup controlId="username" bsSize="large">
          <ControlLabel>Edit Username</ControlLabel>
          <FormControl
            type="text"
            defaultValue={this.UserInfo.username}
            onChange={this.handleChange}
            tabIndex={3}
          />
        </FormGroup>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Edit Email</ControlLabel>
          <FormControl
            type="text"
            defaultValue={this.UserInfo.email}
            onChange={this.handleChange}
            tabIndex={4}
          />
        </FormGroup>
        {/* <FormGroup controlId="roles" bsSize="large">
          <ControlLabel className="buttonlabel">Is Admin?</ControlLabel>
          <ToggleButtonGroup className="left-margin" type="radio" name="isUserClient" bsSize="large" value={this.state.currentrole === 'client' ? 1 : 2}>
            <ToggleButton value={1} tabIndex={5} ref={this.attachRef} onClick={() => this.setState({isUserClient: true, currentrole: 'client'})}>Yes</ToggleButton>
            <ToggleButton value={2} tabIndex={6} ref={this.attachRef} onClick={() => this.setState({isUserClient: false, currentrole: 'customer'})}>No</ToggleButton>
          </ToggleButtonGroup>
          <div className="bold-text align-center top-padding">
            (Admins can access any account's invoices and edit existing users)
          </div>
        </FormGroup> */}
        {this.state.currentrole === 'customer' &&
          <Button
            block
            bsSize="medium"
            onClick={() => this.props.history.push(encodeURI('/users/' + this.UserInfo.id + '/accounts'))}
            tabIndex={7}
          >
            Manage Accounts
          </Button>}
        {this.state.changePassword &&
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            autoFocus
            type="password"
            onChange={this.handleChange}
            disabled={!this.state.changePassword}
            tabIndex={8}
          />
        </FormGroup>}
        {this.state.changePassword &&
        <FormGroup controlId="password_confirmation" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            onChange={this.handleChange}
            disabled={!this.state.changePassword}
            tabIndex={9}
          />
        </FormGroup>}
        {this.state.changePassword &&
        <Button
          block
          bsSize="medium"
          disabled={!this.state.changePassword}
          onClick={() => this.setState({changePassword: false})}
          tabIndex={10}
        >
          Cancel Password Change
        </Button>}
        {!this.state.changePassword &&
        <Button
          block
          bsSize="medium"
          disabled={this.state.changePassword}
          onClick={() => {
            this.setState({changePassword: true});
          }}
          tabIndex={11}
        >
          Change Password
        </Button>}
        <hr />
        <Button
          block
          bsSize="large"
          onClick={() => this.props.history.push(encodeURI('/users'))}
          tabIndex={12}
        >
          Cancel
        </Button>
        <Button
          block
          bsSize="large"
          type="submit"
          tabIndex={13}
        >
          Save
        </Button>
        <hr />
      </form></div>);
    }
}

//* Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { user, apiUrl, token }} = state;
  return {
      //* props.token will now be connected to the store's state.token.'
      user,
      apiUrl,
      token,
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
        activateLoad: (message) => dispatch(activateLoading(message)),
        resetLoad: () => dispatch(resetLoading()),
        handleLogout: () => dispatch(logout()),
        //* handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(User);