import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Display from "../containers/Display";
import Request from "../containers/Request";
import Invoice from "../containers/Invoice";
import Accounts from "../containers/Accounts";
import SimpleCount from "../containers/SimpleCount";

const CustomerRoutes = () =>
  <Switch>
    <Route path="/accounts" exact component={Accounts} />;
    <Route path="/accounts/:custmastid/simplecount" exact component={SimpleCount} />;
    <Route path="/accounts/:custmastid/invoices" exact component={Display} />;
    <Route path="/accounts/:custmastid/request" exact component={Request} />;
    <Route path="/accounts/:custmastid/invoices/:invno" exact component={Invoice} />;
    <Route render={props => {
      return <Redirect to={{ pathname: '/accounts', state: { from: props.location } }} />;
    }} />
  </Switch>
export default CustomerRoutes;