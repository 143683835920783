import React, { Component } from "react";
import { connect } from "react-redux";
import { initLogin, loginError } from "../store/actions/authActions";
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import { Link } from 'react-router-dom';
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Login.css";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
    };
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    // console.log('submitting again');
    this.props.activateLoad('Signing In');
    this.props.handleLogin(this.state.username, this.state.password);
    // console.log(this.props.message.value);
    console.log('login reset');
    this.props.resetLoad();
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="username" bsSize="large">
            <ControlLabel>Username</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
            <div id="error-message">
              {this.props.message.value}
            </div>
          </FormGroup>
          <Button block bsSize="large" disabled={!this.validateForm()} type="submit">
            Login
          </Button>
          <hr />
          <Link to="/register">Register</Link>
        </form>
      </div>
    );
  }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { loading, message }} = state;
  return {
      loading,
      message
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
      handleLogin: (username, password) => dispatch(initLogin(username, password)),
      activateLoad: (message) => dispatch(activateLoading(message)),
      resetLoad: () => dispatch(resetLoading()),
      loginError: (message = '') => dispatch(loginError(message)),
      // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);