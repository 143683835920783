import React, { Component } from "react";
import { connect } from 'react-redux';
import { logout, checkToken } from './store/actions/authActions';
import { activateLoading, resetLoading } from './store/actions/loadingActions';
import { Nav, NavItem, Navbar, NavDropdown } from "react-bootstrap";
import "./App.css";
import ReactLoading from 'react-loading';
import CustomerRoutes from "./routes/CustomerRoutes";
import ClientRoutes from "./routes/ClientRoutes";
import DefaultRoutes from "./routes/DefaultRoutes";
import RouteNavItem from "./components/RouteNavItem";

class App extends Component {

  componentDidMount() {
    this.props.handleCheckToken();
  }

  render() {

    const { user, is_loading, message } = this.props;
    const isCustomer = user !== null && user.roles.map(v => v.name).includes('customer');
    const isClient = user !== null && user.roles.map(v => v.name).includes('client');

    const { custmastid } = this.props.match.params;
    const companynames = [];
    const companyarnos = [];
    const accountlist = [];

    if (user) {
      //const regex = "(?<=/accounts/)[0-9]*(?=/)";
      user.accounts.forEach(element => {
        const path = this.props.location.pathname;
        const option = (
          <RouteNavItem href={path.replace("/accounts/" + custmastid, "/accounts/" + element.custmast_id)}><b>{element.arno + ' - ' + element.name}</b></RouteNavItem>
        );
        companynames[element.custmast_id] = element.name;
        companyarnos[element.custmast_id] = element.arno;
        accountlist.push(option);
      });
    }

    return (
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand id="nav-logo">
              <img alt="company logo" className="logo" src={require('./images/SLTLogo.png')}/>
            </Navbar.Brand>
            <Navbar.Toggle/>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullLeft>
              {isCustomer && custmastid && user !== null && user.accounts.length > 1 && <NavDropdown title={companyarnos[custmastid] + ' - ' + companynames[custmastid]} id="arno-dropdown">
                {[...accountlist]}
              </NavDropdown>}
              {isCustomer && custmastid && user !== null && user.accounts.length === 1 && <NavItem disabled><b>{companynames[custmastid]}</b></NavItem>}
            </Nav>
            <Nav pullRight>
              {(isClient || (custmastid && user !== null && user.accounts.length > 1)) && <RouteNavItem href="/accounts"><b>Accounts</b></RouteNavItem>}
              {isClient && <RouteNavItem href="/users"><b>Users</b></RouteNavItem>}
              {/* {isCustomer && custmastid && <RouteNavItem href={"/accounts/" + custmastid + "/simplecount"}><b><i>SimpleCount</i></b>&nbsp;<sup>&copy;</sup></RouteNavItem>} */}
              {isCustomer && custmastid && <RouteNavItem href={"/accounts/" + custmastid + "/invoices"}><b>Invoices</b></RouteNavItem>}
              {isCustomer && custmastid && <RouteNavItem href={"/accounts/" + custmastid + "/request"}><b>Service Request</b></RouteNavItem>}
              {user === null && <RouteNavItem href="/"><b>Login</b></RouteNavItem>}
              {user !== null && <NavItem onClick={() => this.props.handleLogout()}><b>Logout</b></NavItem>}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div style={{position: 'relative'}}>
          {is_loading && <div style={{position: 'fixed', paddingTop: '12%', width: '100%', height: '100%', top: 0, left: 0, background: '#ffffff', textAlign: 'center', zIndex: 10}}>
            <h1 className="center" style={{fontSize: '38pt'}}>{message}</h1>
            <ReactLoading className="center loading" type={"bars"} color={"#8cc63f"} width={"200px"}/>
          </div>}
          {user === null && <DefaultRoutes/>}
          {isCustomer && !isClient && <CustomerRoutes/>}
          {isClient && <ClientRoutes/>}
        </div>
      </div>
    );
  }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { user, apiUrl, token }, loading: {is_loading, message}} = state;
  return {
    user,
    apiUrl,
    token,
    is_loading,
    message,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
      handleLogout: () => dispatch(logout()),
      handleCheckToken: () => dispatch(checkToken()),
      activateLoad: (message) => dispatch(activateLoading(message)),
      resetLoad: () => dispatch(resetLoading()),
      // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);