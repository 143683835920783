import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn, SearchField } from "react-bootstrap-table";
import { connect } from 'react-redux';
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import './react-tagsinput.css'
import { Button } from "react-bootstrap";
import TagsInput from 'react-tagsinput';
import "./AccountManagement.css";
import ReactLoading from 'react-loading';
//import * as moment from 'moment';

class AccountManagement extends Component {
  constructor(props) {
      super(props);

      this.SelectedCust = {};
      this.CustMast = [];
      this.UserList = {};
      this.FoundUser = false;

      this.state = {
          rows: [],
          tagArray: [],
          selectedArray: [],
          loaded: false,
          tableChange: false,
          search: '',
          total: 0,
          curPage: 1,
          limit: 10,
          innerLoadRender: false,
      };
      this.defaultState = {...this.state};

  }

  //* ============================================ TABLE COMPONENTS >>> ============================================

  onRowSelect = (row, isSelected) => {
    console.log("row =>");
    console.log(row);
    this.addTag({
      custmast_id: parseInt(row['CustmastID']),
      arno: row['ARNO'],
      name: row['ARCOMPANY'],
      display: row['ARNO'] + ' - ' + row['ARCOMPANY'] + ' (ID ' + row['CustmastID'] + ')',
    });
  }

  onPageChange = (page, sizePerPage) => {
    // console.log(page, sizePerPage);
    // console.log('onPageChange');
    this.setState({
      curPage: page,
      limit: sizePerPage,
      tableChange: true,
      innerLoadRender: true,
    },()=>this.getData());
  }

  onSizePerPageList = (sizePerPage) => {
    // console.log('onSizePerPageList');
    this.setState({
      limit: sizePerPage,
      tableChange: true,
      curPage: this.defaultState.curPage,
      innerLoadRender: true,
    },()=>this.getData());
  }

  createCustomSearchField = (props) => {
    return (
      <SearchField
          className='my-custom-class'
          defaultValue={this.state.search}
          placeholder="Search..." />
    );
  }

  onSearchChange = (searchText, colInfos, multiColumnSearch) => {
    // console.log(searchText, colInfos, multiColumnSearch);
    // console.log('onSearchChange');
    this.setState({
      search: searchText,
      loading: true,
      tableChange: true,
      curPage: this.defaultState.curPage,
      innerLoadRender: true,
    },()=>this.getData());
  }

  //* ============================================ <<< TABLE COMPONENTS ============================================

  //* ============================================ RETRIEVAL COMPONENTS >>> ============================================

  async getUser() {
    const { userid } = this.props.match.params;

    // console.log('this.FoundUser not found');
    let userEndPoint = this.props.apiUrl + '/v1/user/' +  userid;
    let userLoad = fetch(userEndPoint, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.props.token,
        }
    })
    .then(async response => {
      if (response.status>=200 && response.status<300) {
        return response.json();
      }
      const obj = await response.json();
      if ('errors' in obj) {
        throw new Error(obj.errors.join("\n"));
      } else {
        throw new Error('Unknown error');
      }
    })
    .then(json => {
      this.FoundUser = true;
      // console.log(json);
      var self = this;
      // console.log(json.accounts);
      json.accounts.forEach(function (account) {
        // console.log(account);
        // console.log('Adding tag for ' + account.arno);
        self.addTag({
          custmast_id: account.custmast_id,
          name: account.name,
          arno: account.arno,
          display: account.arno + ' - ' + account.name + ' (ID ' + account.custmast_id + ')',
        });
      });
      return true;
    })
    .catch(e => {
      alert(e.message);
      this.setState({
        loaded: true,
        tableChange: false,
      });
      return false;
    });

    // this.setState({tags: prevArnos});
    return userLoad;
  }

  async getData() {

    let fetchEndPoint = this.props.apiUrl + '/v1/' + this.props.user.radb.connection + '/custmast?perPage=' + this.state.limit + '&page=' + this.state.curPage;
    fetchEndPoint += (this.state.search != '' ? ('&search=' + this.state.search) : '');
    let accountsLoad = fetch(fetchEndPoint, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.props.token,
        }
    })
    .then(async response => {
      // console.log('first then on getData');
      if (response.status>=200 && response.status<300) {
        return response.json();
      }
      const obj = await response.json();
      if ('errors' in obj) {
        throw new Error(obj.errors.join("\n"));
      } else {
        throw new Error('Unknown error');
      }
    })
    .then(json => {
        // console.log('last then on getData');
        this.CustMast = json.data;
        this.setState({
          page: json.current_page,
          total: json.total,
          limit: json.per_page,
          loaded: true,
          tableChange: false,
          innerLoadRender: false,
        });
        return true;
    })
    .catch(e => {
        alert(e.message);
        this.setState({
          loaded: true,
          tableChange: false,
          innerLoadRender: false,
        });
        return false;
    });
    return accountsLoad;
  }
  
  addTag(row) {
    const newRows = this.state.rows;
    const newSelectedArray = this.state.selectedArray;
    console.log('newRows =>');
    console.log(newRows);
    const existingRowIndex = newRows.map(e => e.display).indexOf(row.display);
    const existingSelectedIndex = newSelectedArray.indexOf(row.custmast_id + '');
    if (existingRowIndex === -1) {
      console.log('ADDING TAG');
      newRows.push(row);
      newSelectedArray.push(row.custmast_id + '');
    } else {
      console.log('REMOVING TAG');
      newRows.splice(existingRowIndex, 1);
      newSelectedArray.splice(existingSelectedIndex, 1);
    }
    this.setState({
      rows: newRows,
      selectedArray: newSelectedArray,
      tagArray: newRows.sort((a, b) => (a.arno > b.arno) ? 1 : ((b.arno > a.arno) ? -1 : 0)).map(element => {
        return element.display;
      }),
    });
    console.log('this.state.rows new value =>');
    console.log(this.state.rows);
    console.log('======================== SELECTED >>> ========================');
    console.log(this.state.selectedArray);
    console.log('======================== <<< SELECTED ========================');
    // console.log(this.state.tags);
  }

  //* ============================================ <<< RETRIEVAL COMPONENTS ============================================



  //* ============================================ SUBMISSION COMPONENTS >>> ============================================

  handleSubmit = async event => {
    event.preventDefault();

      console.log('Submitting...');

      const { userid } = this.props.match.params;
      console.log('grabbed userid');
      this.props.activateLoad('Saving Changes');
      const formData = {
        accountsData: true,
        userData: false,
        rolesData: false,
        passwordData: false,
        ids: this.state.rows.map(e => e.custmast_id),
      };

      fetch(this.props.apiUrl + '/v1/' + this.props.user.radb.connection + '/user/' + userid, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + this.props.token, 
        },
        body: JSON.stringify(formData),
      })
      .then(async response => {
        console.log("Entering first THEN");
        if (response.status>=200 && response.status<300) {
          console.log("got a 200");
          return response.json();
        }
        console.log("didnt got a 200");
        const obj = await response.json();
        if ('errors' in obj) {
          throw new Error(obj.errors.join("\n"));
        } else {
          throw new Error('Unknown error');
        }
      })
      .then(async obj => {
        console.log('about to push to users');
        this.props.history.push(encodeURI('/users'));
      })
      .catch(e => {
        alert("User must have one or more accounts.");
        console.log('Error')
        this.props.resetLoad();
      });

  }

  //* ============================================ <<< SUBMISSION COMPONENTS ============================================

  //* ============================================ MAIN CODE >>> ============================================

  componentDidMount() {
    this.props.activateLoad('Loading Accounts');
    let userResult = this.getUser();
    let dataResult = this.getData();

    Promise.all([userResult, dataResult])
      .then(results => {
        this.props.resetLoad();
      })
      .catch(e => {
        alert('User and/or Account data could not be resolved.');
        this.props.history.push(encodeURI('/users/' + this.props.userid));
      });
    }

  render() {
    
    const defaultRenderTag = (props) => {
      let {tag, key, disabled, classNameRemove, getTagDisplayValue, ...other} = props;
      let attachedRow = this.state.rows.filter(e => getTagDisplayValue(tag) === e.display)[0];
      return (
        <span key={key} {...other}>
          {getTagDisplayValue(tag)}
          <span className={classNameRemove} onClick={(e) => this.addTag(attachedRow)} />
        </span>
      )
    }

    const selectRowProp = {
      mode: 'checkbox',
      bgColor: '#cde69c',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      selected: this.state.selectedArray,
    };

    const fetchInfo = {
      dataTotalSize: this.state.total,
    };

    return (<div className="AccountManagement">
      <div className="panel panel-default">
        <div className="panel-heading panelheader">
          <div className="bold-text align-center">
              User's Accounts
          </div>
        </div>
        <div className="panel-body spacer">
          <TagsInput value={this.state.tagArray} disabled renderTag={defaultRenderTag} onChange={this.handleChange} />
          <div className="buttoner">
            <Button block bsSize="large" type="submit" onClick={this.handleSubmit}>
              Save
            </Button>
          </div>
        </div>
    </div>
    <div className="panel panel-default">
      <div className="panel-heading panelheader">
          <div className="bold-text align-center">
              All Accounts
          </div>
        </div>
        <div className="panel-body spacer">
          <div>
            {this.state.innerLoadRender && <div style={{position: 'absolute', paddingTop: '12%', width: '100%', height: '100%', top: 0, left: 0, background: '#ffffff', textAlign: 'center', zIndex: 10}}>
              <h1 className="center" style={{fontSize: '38pt'}}>Loading Accounts...</h1>
              <ReactLoading className="center loading" type={"bars"} color={"#8cc63f"} width={"200px"}/>
            </div>}
            {!this.state.innerLoadRender && <BootstrapTable search selectRow={ selectRowProp } data={this.CustMast} striped={true} hover={true} remote={true} pagination={true}
              fetchInfo={{dataTotalSize: this.state.total}}
              options={ {
                clearSearch: true,
                remote: true,
                searchField: this.createCustomSearchField,
                searchDelayTime: 1000,
                searchPosition: 'left',
                sizePerPage: this.state.limit,
                page: this.state.curPage,
                onPageChange: this.onPageChange,
                onSizePerPageList: this.onSizePerPageList,
                onSearchChange: this.onSearchChange,
                sizePerPageList: [ 5, 10, 15, 25, 50 ],
                fetchInfo: { fetchInfo },
              } }>
              <TableHeaderColumn dataField="CustmastID" isKey={true} dataAlign="center">Customer ID</TableHeaderColumn>
              <TableHeaderColumn dataField="ARNO" dataAlign="center">Account #</TableHeaderColumn>
              <TableHeaderColumn dataField="ARCOMPANY" dataAlign="center">Name</TableHeaderColumn>
              <TableHeaderColumn dataField="STMTEMAIL" dataAlign="center">Email</TableHeaderColumn>
            </BootstrapTable>}
          </div>
        </div>
      </div>
    </div>);
  }
}

//* ============================================ <<< MAIN CODE ============================================

//* ============================================ REDUX COMPONENTS >>> ============================================

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { user, apiUrl, token }} = state;
  return {
      //props.token will now be connected to the store's state.token.'
      user,
      apiUrl,
      token,
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
        activateLoad: (message) => dispatch(activateLoading(message)),
        resetLoad: () => dispatch(resetLoading()),
        // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountManagement);

//* ============================================ <<< REDUX COMPONENTS ============================================