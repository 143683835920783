import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn, SearchField } from "react-bootstrap-table";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from "react-redux";
import { logout } from '../store/actions/authActions';
import { FormControl, ControlLabel } from "react-bootstrap";
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import "./Display.css";
import * as moment from 'moment';

class Display extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            clickedOpenItems: [],
            retrievedCustAct: null,
            retrievedCustMast: {
                ADDR1: '',
                ADDR2: '',
                ARCITY: '',
                ARSTATE: '',
                ARZIP: '',
            },
            retrievedOpenItems: [],
        };

        this.lastLoadCustMastId = null;
        this.firstMount = false;
    }

    onRowSelect = (row) => {
        const { custmastid } = this.props.match.params;
        this.props.history.push(encodeURI('/accounts/' + custmastid + '/invoices/' + row['invno'].trim()));
    }

    async getData() {
        const { custmastid } = this.props.match.params;
        const { user, apiUrl, token } = this.props;

        this.lastLoadCustMastId = custmastid;

        this.props.activateLoad('Loading Invoices');
        console.log(apiUrl + '/v1/' + user.radb.connection + '/custmast/' + custmastid + '?with[]=custact&with[]=openitems');
        let dataFetch = fetch (apiUrl + '/v1/' + user.radb.connection + '/custmast/' + custmastid +'?with[]=custact&with[]=openitems', {
            method: 'GET',
            //credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
        .then(async response => {
            if (response.status>=200 && response.status<300) {
              return response.json();
            }
            const obj = await response.json();
            if ('errors' in obj) {
              throw new Error(obj.errors.join("\n"));
            } else {
              throw new Error('Unknown error');
            }
        })
        .then(json => {

            const tempCustMast = json;
            const tempCustAct = json.custact;
            const tempOpenItems = json.openitems;

            tempCustMast.ARCOMPANY = tempCustMast.ARCOMPANY.replace(/\0.*$/g,'');
            tempCustMast.ADDR1 = tempCustMast.ADDR1.replace(/\0.*$/g,'');
            tempCustMast.ADDR2 = tempCustMast.ADDR2.replace(/\0.*$/g,'');
            tempCustMast.ARCITY = tempCustMast.ARCITY.replace(/\0.*$/g,'');
            tempCustMast.ARSTATE = tempCustMast.ARSTATE.replace(/\0.*$/g,'');
            tempCustMast.ARZIP = tempCustMast.ARZIP.replace(/\0.*$/g,'');

            this.setState({
                retrievedCustAct: tempCustAct,
                retrievedOpenItems: tempOpenItems,
                retrievedCustMast: tempCustMast,
            });

            if (this.state.retrievedCustAct !== '') {

                this.setState({
                    retrievedCustAct: {
                        TotalDue: parseFloat(this.state.retrievedCustAct.CURDUE) + parseFloat(this.state.retrievedCustAct.BAL30) + parseFloat(this.state.retrievedCustAct.BAL60) + parseFloat(this.state.retrievedCustAct.BAL90) + parseFloat(this.state.retrievedCustAct.BAL120),
                        LPYDATE: moment(this.state.retrievedCustAct.LPYDATE, "YYYYMMDD").format("MM/DD/YYYY"),
                        LAINVDATE: moment(this.state.retrievedCustAct.LAINVDATE, "YYYYMMDD").format("MM/DD/YYYY"),
                        LPYAMT: this.state.retrievedCustAct.LPYAMT < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.LPYAMT).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.LPYAMT).toFixed(2),
                        LASTIAMT: this.state.retrievedCustAct.LASTIAMT < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.LASTIAMT).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.LASTIAMT).toFixed(2),
                        MTDSLS: this.state.retrievedCustAct.MTDSLS < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.MTDSLS).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.MTDSLS).toFixed(2),
                        BAL30: this.state.retrievedCustAct.BAL30 < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.BAL30).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.BAL30).toFixed(2),
                        BAL60: this.state.retrievedCustAct.BAL60 < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.BAL60).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.BAL60).toFixed(2),
                        BAL90: this.state.retrievedCustAct.BAL90 < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.BAL90).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.BAL90).toFixed(2),
                        BAL120: this.state.retrievedCustAct.BAL120 < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.BAL120).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.BAL120).toFixed(2),
                        CURDUE: this.state.retrievedCustAct.CURDUE < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.CURDUE).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.CURDUE).toFixed(2),
                        CURFINCHG: this.state.retrievedCustAct.CURFINCHG < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.CURFINCHG).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.CURFINCHG).toFixed(2),
                        UNPFINCHG: this.state.retrievedCustAct.UNPFINCHG < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.UNPFINCHG).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.UNPFINCHG).toFixed(2),
                        TotalDue: this.state.retrievedCustAct.TotalDue < 0 ? "-$" + (-1 * parseFloat(this.state.retrievedCustAct.TotalDue).toFixed(2)) : "$" + parseFloat(this.state.retrievedCustAct.TotalDue).toFixed(2),
                    },
                });
            }

            if (this.state.OpenItems !== '') {

                this.setState({
                    retrievedOpenItems: tempOpenItems.filter(v => v.DESCRIP === 'Invoice')
                    .map(v => {
                        v.txnid = v.OpenitemID;
                        v.txntype = v.DESCRIP;
                        v.txnpostdate = moment(v.POSTDATE, "YYYYMMDD").format("MM/DD/YYYY");
                        v.txnamount = v.OIAMT >= 0 ? "$" + parseFloat(v.OIAMT).toFixed(2) : "-$" + (-1 * parseFloat(v.OIAMT)).toFixed(2);
                        v.invno = v.INVNO;
                        v.invduedate = v.ITYPE==='1' ? moment(v.DUEDATE, "YYYYMMDD").format("MM/DD/YYYY") : "N/A";
                        v.remainingbalance = v.INVBAL >= 0 ? "$" + parseFloat(v.INVBAL).toFixed(2) : "-$" + (-1 * parseFloat(v.INVBAL)).toFixed(2);
                        return v;
                    }).sort(function(a, b){return new Date(b.txnpostdate) - new Date(a.txnpostdate)}),
                });
                
            }            
        })
        .catch(e => {
            //error is handled in componentDidMount
        });

        return dataFetch;
    }

    componentDidMount() {
        let fetchResult = this.getData();
        console.log('pre promise');

        Promise.all([fetchResult])
        .then(result => {
            console.log('inside promise');
            this.props.resetLoad();
            this.firstMount = true;
        })
        .catch( e => {
            alert(e.message);
            this.props.handleLogout();
        })
    }

    componentDidUpdate() {
        if (this.firstMount && this.props.match.params.custmastid !== this.lastLoadCustMastId) {
            let fetchResult = this.getData();

            Promise.all([fetchResult])
            .then(result => {
                if (this.CustAct === null) {
                    console.log('pre reset here');
                    this.props.resetLoad();
                }
            })
            .catch( e => {
                alert(e.message);
                this.props.handleLogout();
            })
        }
    }

    createCustomSearchField = (props) => {
        return (
            <SearchField
                className='my-custom-class'
                defaultValue={props.defaultSearch}
                placeholder={props.searchPlaceholder} />
        );
    }

    render() {
       
        const { custmastid } = this.props.match.params;

        //console.log(this.props.CustMast);
        const selectRowProp = {
            mode: 'radio',
            clickToSelect: true,
            bgColor: '#ff8c66',
            onSelect: this.onRowSelect
        };

        const options = {
            clearSearch: true,
            searchField: this.createCustomSearchField,
            searchPosition: 'left',
            paginationPosition: 'bottom',
            // defaultSortName: 'InvoiceDate',
            // defaultSortOrder: 'desc'
        };

        return (<div>
            <form onSubmit={this.handleSubmit}>
                <div className="Display panel panel-default">
                    <div className="panel-heading panelheader">
                        <div id="coname" className="title align-center">
                            {this.state.retrievedCustMast.ARCOMPANY}
                        </div>
                        <div className="bold-text align-center">
                            {this.state.retrievedCustMast.ADDR1.length > 0 ? this.state.retrievedCustMast.ADDR1 + " | " : ""} {this.state.retrievedCustMast.ADDR2.length > 0 ? this.state.retrievedCustMast.ADDR2 + " | " : ""} {this.state.retrievedCustMast.ARCITY.length > 0 ? this.state.retrievedCustMast.ARCITY + ", " : ""} {this.state.retrievedCustMast.ARSTATE.length > 0 ? this.state.retrievedCustMast.ARSTATE + " " : ""} {this.state.retrievedCustMast.ARZIP.length > 0 ? this.state.retrievedCustMast.ARZIP : ""}
                        </div>
                    </div>
                    <div id="toppanel" className="panel-body spacer2">
                        <div className="row">
                            <div id="activitypanel" className="panel panel-default col-sm-6 padless">
                                <div className="panel-heading panelheader">
                                    <div className="bold-text align-center">
                                        Activity
                                    </div>
                                </div>
                                <div className="panel-body spacer">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <ControlLabel>Last Pay:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.LACHKNO} />
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.LPYDATE} />
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.LPYAMT} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <ControlLabel>Last Inv:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.LASTINV} />
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.LAINVDATE} />
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.LASTIAMT} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="balancespanel" className="panel panel-default col-sm-6 padless">
                                <div className="panel-heading panelheader">
                                    <div className="bold-text align-center">
                                        Balances
                                    </div>
                                </div>
                                <div className="panel-body spacer">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <ControlLabel>Cur Fin Chg:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.CURFINCHG} />
                                        </div>
                                        <div className="col-sm-3">
                                            <ControlLabel>ADTP:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.ADTP} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <ControlLabel>Unpaid F/C:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.UNPFINCHG} />
                                        </div>
                                        <div className="col-sm-3">
                                            <ControlLabel>Cur Due:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.CURDUE} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <ControlLabel>Bal 30:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.BAL30} />
                                        </div>
                                        <div className="col-sm-3">
                                            <ControlLabel>Bal 60:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.BAL60} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <ControlLabel>Bal 90:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.BAL90} />
                                        </div>
                                        <div className="col-sm-3">
                                            <ControlLabel>Bal 120:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.BAL120} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">

                                        </div>
                                        <div className="col-sm-3">
                                            <ControlLabel>Total Due:</ControlLabel>
                                        </div>
                                        <div className="col-sm-3">
                                            <FormControl type="text" disabled value={this.state.retrievedCustAct === null ? "" : this.state.retrievedCustAct.TotalDue} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">
                        <div className="col-sm-push-3 col-sm-3">
                            <Button block bsSize="large" type="submit" disabled={!this.validateForm()}>Send Selected Invoices</Button>
                        </div>
                        <div className="col-sm-push-3 col-sm-3">
                            <Button block bsSize="large" type="submit" disabled={!this.validateForm()}>Show Invoice Details</Button>
                        </div>
                    </div>*/}
                    {/*v.txnpostdate = moment(v.POSTDATE, "YYYYMMDD").format("MM/DD/YYYY");
                    v.txntype = v.Description;
                    v.invduedate = v.ITYPE==1 ? moment(v.DueDate, "YYYYMMDD").format("MM/DD/YYYY") : "N/A";
                    v.txnamount = "$" + parseFloat(v.OIAMT).toFixed(2);
                    v.remainingbalance = "$" + parseFloat(v.InvBal).toFixed(2);*/}
                    <hr className="line"/>
                    <div className="panel-body spacer">
                        <BootstrapTable search data={this.state.retrievedOpenItems} options={options} selectRow={selectRowProp} striped={true} hover={true} className="openitemstable">
                            <TableHeaderColumn dataField="txnpostdate" dataAlign="center">Posted Date</TableHeaderColumn>
                            <TableHeaderColumn dataField="invno" dataAlign="center">Invoice #</TableHeaderColumn>
                            <TableHeaderColumn dataField="txnid" dataAlign="center" isKey={true}>Transaction ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="txntype" dataAlign="center">Type</TableHeaderColumn>
                            <TableHeaderColumn dataField="txnamount" dataAlign="center">Amount</TableHeaderColumn>
                            <TableHeaderColumn dataField="invduedate" dataAlign="center">Due Date</TableHeaderColumn>
                            <TableHeaderColumn dataField="remainingbalance" dataAlign="center">Balance</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </form>
        </div>);
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { user, apiUrl, token }, loading: {is_loading}} = state;
  return {
      //props.token will now be connected to the store's state.token.'
      user,
      apiUrl,
      token,
      is_loading,
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
        activateLoad: (message) => dispatch(activateLoading(message)),
        resetLoad: () => dispatch(resetLoading()),
        handleLogout: () => dispatch(logout()),
        // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Display);