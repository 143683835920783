import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn, SearchField } from "react-bootstrap-table";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Users.css";
//import * as moment from 'moment';
import { connect } from 'react-redux';
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import FaCheck from 'react-icons/lib/fa/check';
import FaClose from 'react-icons/lib/fa/close';

function approvedIconFormatter(cell, row) {
  return cell ? <FaCheck color="#4caf50"/> : <FaClose color="#f44336"/>;
}

class Users extends Component {
  constructor(props) {
      super(props);

      this.SelectedCust = {};
      this.CustMast = {};
      this.UserList = [];

      this.state = {
          loading: true,
          // loaded: false,
          search: '',
          total: 0,
          curPage: 1,
          limit: 10,
      };

  }


  onRowSelect = (row, isSelected, e) => {
    this.props.history.push(encodeURI('/users/' + row['id']));
  }

  onPageChange = (page, sizePerPage) => {
    // console.log(page, sizePerPage);
    this.setState({
      curPage: page,
      limit: sizePerPage,
      loading: true,
    },()=>this.getData());
  }

  onSizePerPageList = (sizePerPage) => {
    this.setState({
      limit: sizePerPage,
      loading: true,
    },()=>this.getData());
  }

  onSearchChange = (searchText, colInfos, multiColumnSearch) => {
    // console.log(searchText, colInfos, multiColumnSearch);
    this.setState({
      search: searchText,
      loading: true,
    },()=>this.getData());
  }

  async getData() {
    let fetchEndPoint = this.props.apiUrl + '/v1/user?perPage=' + this.state.limit + '&page=' + this.state.curPage;
    fetchEndPoint += (this.state.search != '' ? ('&search=' + this.state.search) : '');
    fetch(fetchEndPoint, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + this.props.token,
      }
    })
    .then(async response => {
      if (response.status>=200 && response.status<300) {
        return response.json();
      }
      const obj = await response.json();
      if ('errors' in obj) {
        throw new Error(obj.errors.join("\n"));
      } else {
        throw new Error('Unknown error');
      }
    })
    .then(json => {
      this.UserList = json.data;
      this.setState({
        loading: false,
        page: json.current_page,
        total: json.total,
        limit: json.per_page,
      });
    })
    .catch(e => {
        alert(e.message);
    });
  }

  componentDidMount() {
    this.props.activateLoad('Loading Users')
    this.getData();
    this.props.resetLoad();
  }

  createCustomSearchField = (props) => {
        return (
            <SearchField
                className='my-custom-class'
                defaultValue={this.state.search}
                placeholder="Search..." />
        );
    }

  render() {

    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: this.onRowSelect
    };

    const fetchInfo = {
      dataTotalSize: this.state.total,
    };

    return (<div className="Users panel panel-default">
      <div className="panel-heading panelheader">
        <div className="bold-text align-center">
            Users
        </div>
      </div>
      <div className="panel-body spacer">
        <BootstrapTable search selectRow={ selectRowProp } data={this.UserList} striped={true} hover={true} remote={true} pagination={true}
        fetchInfo={{dataTotalSize: this.state.total}}
          options={ {
            clearSearch: true,
            remote: true,
            searchField: this.createCustomSearchField,
            searchDelayTime: 1000,
            searchPosition: 'left',
            sizePerPage: this.state.limit,
            page: this.state.curPage,
            onPageChange: this.onPageChange,
            onSizePerPageList: this.onSizePerPageList,
            onSearchChange: this.onSearchChange,
            sizePerPageList: [ 5, 10, 15, 25, 50 ],
            fetchInfo: { fetchInfo },
          } }>
          <TableHeaderColumn dataField="name" dataAlign="center" isKey={true}>Name</TableHeaderColumn>
          <TableHeaderColumn dataField="company" dataAlign="center">Company</TableHeaderColumn>
          <TableHeaderColumn dataField="username" dataAlign="center">Username</TableHeaderColumn>
          <TableHeaderColumn dataField="email" dataAlign="center">Email</TableHeaderColumn>
          <TableHeaderColumn dataField="approved" dataFormat={approvedIconFormatter} dataAlign="center">Approved</TableHeaderColumn>
        </BootstrapTable>
      </div>
    </div>);
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { user, apiUrl, token }} = state;
  return {
      //props.token will now be connected to the store's state.token.'
      user,
      apiUrl,
      token,
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
        activateLoad: (message) => dispatch(activateLoading(message)),
        resetLoad: () => dispatch(resetLoading()),
        // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);