import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
import { initRegister } from '../store/actions/authActions';
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Register.css";
import getAllUrlParams from '../helpers/url';

const urlParams = getAllUrlParams(window.location.href);
let radb = '';
let hasUrlConnectionId = false;
if ('radb' in urlParams) {
  radb = urlParams.radb;
  hasUrlConnectionId = true;
}

class Register extends Component {

  constructor(props) {
    super(props);

    this.state = {
      company: "",
      name: "",
      email: "",
      username: "",
      password: "",
      password_confirmation: "",
      radb: radb,
      coid: "", //hard coded on backend so doesnt matter now
      arno: "",
      lastinv: "",
      lastiamt: "",
      hasUrlConnectionId: hasUrlConnectionId,
    };
  }

  validateForm() {
    return (this.state.username.length > 0
     && this.state.name.length > 0
     && this.state.email.length > 0
     && this.state.password.length > 0
     && this.state.password_confirmation.length === this.state.password.length
     && this.state.radb.length > 0
     && this.state.arno.length > 0
     && this.state.lastinv.length > 0
     && this.state.lastiamt.length > 0);
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    const registration = {
      company: this.state.company,
      name: this.state.name,
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      radb: this.state.radb,
      arno: this.state.arno,
      coid: 1,      //Hardcoded on backend to 1, but still requires response
      lastinv: this.state.lastinv,
      lastiamt: this.state.lastiamt,
    };

    this.props.activateLoad('Registering User');
    this.props.handleRegister(registration);
    console.log('register reset');
    this.props.resetLoad();

    this.props.history.push(encodeURI('/login'));
  }

  render() {
    return (
      <div className="Register">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="company" bsSize="large">
            <ControlLabel>Company Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.company}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="name" bsSize="large">
            <ControlLabel>Full Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="username" bsSize="large">
            <ControlLabel>Username</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <FormGroup controlId="password_confirmation" bsSize="large">
            <ControlLabel>Confirm Password</ControlLabel>
            <FormControl
              value={this.state.password_confirmation}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <FormGroup controlId="arno" bsSize="large">
            <ControlLabel>AR Number</ControlLabel>
            <FormControl
              value={this.state.arno}
              onChange={this.handleChange}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="lastinv" bsSize="large">
            <ControlLabel>Last Invoice #</ControlLabel>
            <FormControl
              value={this.state.lastinv}
              onChange={this.handleChange}
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="lastiamt" bsSize="large">
            <ControlLabel>Last Invoice Amount</ControlLabel>
            <FormControl
              value={this.state.lastiamt}
              onChange={this.handleChange}
              type="text"
            />
          </FormGroup>
          {this.state.hasUrlConnectionId ? '' :
            <FormGroup controlId="radb" bsSize="large">
              <ControlLabel>Register Code</ControlLabel>
              <FormControl
                value={this.state.radb}
                onChange={this.handleChange}
                type="text"
              />
            </FormGroup>
          }
          <Button
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
          >
            Register
          </Button>
          <hr />
          <Link to="/">Back to Login</Link>
        </form>
      </div>
    );
  }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { loading }} = state;
  return {
      //props.token will now be connected to the store's state.token.'
      loading,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleRegister: (registration) => dispatch(initRegister(registration)),
    activateLoad: (message) => dispatch(activateLoading(message)),
    resetLoad: () => dispatch(resetLoading()),
    // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);