import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn, SearchField } from "react-bootstrap-table";
import { connect } from 'react-redux';
import { activateLoading, resetLoading } from '../store/actions/loadingActions';
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Accounts.css";
//import * as moment from 'moment';

class Accounts extends Component {
  constructor(props) {
      super(props);

      this.SelectedCust = {};
      this.CustMast = [];
      this.UserList = {};

      this.state = {
          loaded: false,
          tableChange: false,
          search: '',
          total: 0,
          curPage: 1,
          limit: 10,
      };
      this.defaultState = {...this.state};

  }


  onRowSelect = (row) => {
    // console.log('onRowSelect');
    this.props.history.push(encodeURI('/accounts/' + row['CustmastID'] + '/invoices'));
  }

  onPageChange = (page, sizePerPage) => {
    // console.log(page, sizePerPage);
    // console.log('onPageChange');
    this.setState({
      curPage: page,
      limit: sizePerPage,
      tableChange: true,
    },()=>this.getData());
  }

  onSizePerPageList = (sizePerPage) => {
    // console.log('onSizePerPageList');
    this.setState({
      limit: sizePerPage,
      tableChange: true,
      curPage: this.defaultState.curPage,
    },()=>this.getData());
  }

  onSearchChange = (searchText, colInfos, multiColumnSearch) => {
    // console.log(searchText, colInfos, multiColumnSearch);
    // console.log('onSearchChange');
    this.setState({
      search: searchText,
      loading: true,
      tableChange: true,
      curPage: this.defaultState.curPage,
    },()=>this.getData());
  }

  async getData() {

    if ((this.state.loaded || this.props.loading) && !this.state.tableChange) {
      return;
    }

    this.props.activateLoad('Loading Accounts');
    let fetchEndPoint = this.props.apiUrl + '/v1/' + this.props.user.radb.connection + '/custmast?perPage=' + this.state.limit + '&page=' + this.state.curPage;
    fetchEndPoint += (this.state.search != '' ? ('&search=' + this.state.search) : '');
    fetch(fetchEndPoint, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.props.token,
        }
    })
    .then(async response => {
      // console.log('first then on getData');
      if (response.status>=200 && response.status<300) {
        return response.json();
      }
      const obj = await response.json();
      if ('errors' in obj) {
        throw new Error(obj.errors.join("\n"));
      } else {
        throw new Error('Unknown error');
      }
    })
    .then(json => {
        // console.log('last then on getData');
        this.CustMast = json.data;
        this.setState({
          page: json.current_page,
          total: json.total,
          limit: json.per_page,
          loaded: true,
          tableChange: false,
        });
        this.props.resetLoad();
    })
    .catch(e => {
        alert(e.message);
        this.setState({
          loaded: true,
          tableChange: false,
        });
        this.props.resetLoad();
    });
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.accounts.length == 1) {
      this.props.history.push(encodeURI('/accounts/' + user.accounts[0].custmast_id + '/invoices'));
    }

    if (!this.state.loaded) {
      this.getData();
    }
  }

  createCustomSearchField = (props) => {
        return (
            <SearchField
                className='my-custom-class'
                defaultValue={this.state.search}
                placeholder="Search..." />
        );
    }

  render() {

    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: this.onRowSelect
    };

    const fetchInfo = {
      dataTotalSize: this.state.total,
    };

    return (<div className="Accounts panel panel-default">
      <div className="panel-heading panelheader">
        <div className="bold-text align-center">
            Accounts
        </div>
      </div>
      <div className="panel-body spacer">
        <BootstrapTable search selectRow={ selectRowProp } data={this.CustMast} striped={true} hover={true} remote={true} pagination={true}
          fetchInfo={{dataTotalSize: this.state.total}}
          options={ {
            clearSearch: true,
            remote: true,
            searchField: this.createCustomSearchField,
            searchDelayTime: 1000,
            searchPosition: 'left',
            sizePerPage: this.state.limit,
            page: this.state.curPage,
            onPageChange: this.onPageChange,
            onSizePerPageList: this.onSizePerPageList,
            onSearchChange: this.onSearchChange,
            sizePerPageList: [ 5, 10, 15, 25, 50 ],
            fetchInfo: { fetchInfo },
          } }>
          <TableHeaderColumn dataField="ARNO" dataAlign="center">Account #</TableHeaderColumn>
          <TableHeaderColumn dataField="ARCOMPANY" dataAlign="center">Name</TableHeaderColumn>
          <TableHeaderColumn dataField="STMTEMAIL" dataAlign="center">Email</TableHeaderColumn>
          <TableHeaderColumn dataField="CustmastID" dataAlign="center" isKey={true}>Customer ID</TableHeaderColumn>
        </BootstrapTable>
      </div>
    </div>);
  }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = (state) => {
  const { auth: { user, apiUrl, token }} = state;
  return {
      //props.token will now be connected to the store's state.token.'
      user,
      apiUrl,
      token,
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
        activateLoad: (message) => dispatch(activateLoading(message)),
        resetLoad: () => dispatch(resetLoading()),
        // handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);