import constants from "./constants";
import store from '../';

//* --------- GENERAL AUTH ACTIONS ---------
const fetchUser = () => dispatch => {
    // console.log("fetching user");
    const auth = store.getState().auth;
    const full = auth.apiUrl;
    // console.log(full + '/v1/details');
    const url = new URL(full + '/v1/details');

    return fetch(url, {
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + auth.token,
        }
    })
    .then(async response => {
        // console.log("fetching user then");
        if (response.status>=200 && response.status<300) {
            // console.log("returning fetched user");
            return response.json();
        }
        const obj = await response.json();
        if ('errors' in obj) {
            // console.log("fetching user error 1");
            throw new Error(obj.errors.join(", "));
        } else {
            // console.log("fetching user error 2");
            throw new Error('Unknown error');
        }
    })
    .then(json => {
        // console.log("about to set user");
        // console.log(json.user);
        dispatch(setUser(json.user));
    })
    // .catch(e => dispatch(userError(e))); // dont catch this one, let the login action do that
}

const setUser = user => ({
    type: constants.SET_USER,
    value: user,
});

export const setToken = token => ({
    type: constants.SET_TOKEN, 
    value: token,
});

export const checkToken = () => dispatch => {
    // console.log("check token thingy");
    dispatch(tryLogin());

    return dispatch(fetchUser())
    .then(() => dispatch(loginSuccess()))
    .catch(e => dispatch(loginError('')));
}

//* --------- LOGIN ACTIONS ---------
export const initLogin = (username, password) => dispatch => {
    // console.log("trying login");
    dispatch(tryLogin());

    const state = store.getState();

    const { auth: { apiUrl }} = state;

    const loginBody = JSON.stringify({
        username: username,
        password: password,
    });

    return fetch(apiUrl + '/v1/login', {
        method: 'POST',
        body: loginBody,
    })
    .then(async response => {
        // console.log("then on login");
        if (response.status>=200 && response.status<300) {
            // console.log("returning login successfully");
            return response.json();
        }
        const obj = await response.json();
        if ('errors' in obj) {
            // console.log("login error 1");
            throw new Error(obj.errors.join(", "));
        } else {
            // console.log("login error 2");
            throw new Error('Unknown error');
        }
    })
    .then(json => dispatch(setToken(json.token)))
    .then(() => dispatch(fetchUser()))
    .then(() => {
        // console.log("try loginsuccess");
        dispatch(loginSuccess());
    })
    .catch(e => {
        // console.log("hit an error");
        dispatch(loginError(e.message));
    });
}

// const userError = e => ({
//     type: constants.USER_ERROR,
//     value: e
// })

const tryLogin = () => ({
    type: constants.LOGIN_TRY
});

const loginSuccess = () => ({
    type: constants.LOGIN_SUCCESS
});

export const loginError = e => ({
    type: constants.LOGIN_ERROR,
    value: e
});

//* --------- REGISTER ACTIONS ---------
export const initRegister = (registration) => dispatch => {
    console.log('Beginning registration');
    dispatch(tryRegister());
    console.log('line 127');

    const state = store.getState();
    console.log('line 130');
    const { auth: { apiUrl }} = state;
    console.log(apiUrl);
    const registerBody = JSON.stringify(registration);
    console.log('line 1234');

    console.log('Starting Fetch...');

    return fetch(apiUrl + '/v1/register', {
        method: 'POST',
        body: registerBody,
    })
    .then(async response => {
        console.log('First then');
        if (response.status>=200 && response.status<300) {
          console.log('successful');
          return response.json();
        }
        console.log('unsuccessful');
        const obj = await response.json();
        if ('errors' in obj) {
          throw new Error(obj.errors.join(", "));
        } else {
          throw new Error('Unknown error');
        }
    })
    .then(json => dispatch(setToken(json.token)))
    .then(() => dispatch(fetchUser()))
    .then(() => dispatch(loginSuccess()))
    .catch(e => dispatch(loginError(e.message)));
}

const tryRegister = () => ({
    type: constants.REGISTER_TRY
});

//* --------- LOGOUT ACTIONS ---------
export const logout = () => {
    // console.log('Logging Out');
    return { type: constants.LOGOUT };
}