import constants from './actions/constants';

export const defaultState = {
    auth: {
        tokenChecked: false,
        loading: false,
        message: {
            id: null,
            value: '',
        },
        token: '',
        authorized: false,
        user: null,
        registration: null,
        apiUrl: (process.env.NODE_ENV === "development" || window.location.hostname.match("portaldev.laundrytechnology.com") !== null) ? 'https://apidev.laundrytechnology.com' : 'https://api.laundrytechnology.com',
    },
    loading: {
        is_loading: false,
        message: 'Loading Info',
    },
    display: {
        width: 0,
        height: 0,
        mobileMode: true,
    },
};

let initialState = {...defaultState};

const storedStateJSON = localStorage.getItem(constants.LOCAL_STORAGE_KEY);
if (storedStateJSON !== null) {
    try {
        const storedState = JSON.parse(storedStateJSON);
        initialState.auth.token = storedState.auth.token;
    } catch (e) {
        // wasn't json i guess, no worries
    }
}

export default initialState;